var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('ais-instant-search',{attrs:{"index-name":"artWorks","search-client":_vm.searchClient}},[_c('b-row',[_c('b-col',{staticClass:"col-md-3 col-lg-2 nav-left"},[_c('b-nav',{staticClass:"d-flex justify-content-center",attrs:{"id":"sidebar-1"}},[_c('b-navbar',{staticClass:"px-1 d-flex flex-column"},[_c('div',{staticClass:"d-flex flex-column d-md-none"},[_c('b-navbar-brand',{staticClass:"d-flex flex-column align-items-center navbar-mobile"},[_c('img',{staticClass:"mb-3",attrs:{"src":"https://raw.githubusercontent.com/meilisearch/integration-guides/master/assets/logos/logo.svg","alt":"Meilisearch logo","width":"70","height":"70"}}),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('p',{staticClass:"header-title"},[_vm._v("Meilisearch x MoMA")]),_c('p',{staticClass:"disclaimer"},[_vm._v("Enjoy searching with Meilisearch!")])])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"filters mt-5"},[_c('ais-clear-refinements',{staticStyle:{"text-align":"center"},attrs:{"class-names":{
                    'ais-ClearRefinements-button': 'mybtn mybtn--clear', 
                    'ais-ClearRefinements-button--disabled': 'mybtn--clear--disabled' 
                  }}},[_c('span',{attrs:{"slot":"resetLabel"},slot:"resetLabel"},[_vm._v("Clear filters")])]),_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticClass:"search-panel__filters d-flex flex-column align-items-start"},[_c('h4',{staticClass:"filters mt-4",on:{"click":function($event){$event.preventDefault();filter.isExpanded = !filter.isExpanded}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.whichIcon(filter.name),"size":"xs"}}),_vm._v(" "+_vm._s(filter.name)+" "),_c('font-awesome-icon',{attrs:{"icon":filter.isExpanded? 'chevron-up' : 'chevron-down',"size":"xs"}})],1),_c('div',{staticClass:"d-flex"},[_c('ais-refinement-list',{directives:[{name:"show",rawName:"v-show",value:(filter.isExpanded),expression:"filter.isExpanded"}],attrs:{"limit":5,"show-more":filter.name === 'Gender'? false : true,"transform-items":_vm.transformRefinementListItem,"attribute":filter.name,"class-names":{
                        'ais-RefinementList-showMore': 'btn btn-secondary btn-sm',
                        'ais-RefinementList': 'mx-auto'
                      }}})],1)])})],2),_c('b-button',{staticClass:"d-md-none",attrs:{"size":"sm","variant":"light"},on:{"click":function($event){$event.preventDefault();_vm.show=!_vm.show}}},[_vm._v(_vm._s(this.show? 'HIDE FILTERS' : 'SHOW FILTERS'))])],1)],1)],1),_c('b-col',{staticClass:"col-md-9 col-lg-10"},[_c('b-row',[_c('b-col',[_c('b-navbar',{staticClass:"pl-5 d-none d-md-flex flex-column align-items-start"},[_c('b-navbar-brand',{staticClass:"d-flex justify-content-center align-items-center"},[_c('img',{attrs:{"src":"https://raw.githubusercontent.com/meilisearch/integration-guides/master/assets/logos/logo.svg","alt":"Meilisearch logo","height":"60px"}}),_c('p',{staticClass:"header-title my-0"},[_vm._v(" Meilisearch x MoMA")])]),_c('div',{staticClass:"disclaimer disclaimer-desktop"},[_vm._v("Enjoy searching with Meilisearch!")])],1)],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('b-col',{staticClass:"col-10 mx-auto "},[_c('b-row',[_c('b-col',{staticClass:"col-12 col-md-10 my-3 mx-auto d-flex up-bar"},[_c('ais-stats'),_c('ais-sort-by',{attrs:{"items":[
                    { value: 'artWorks', label: 'Featured' },
                    { value: 'artWorks:DateToSortBy:asc', label: 'Date asc.' },
                    { value: 'artWorks:DateToSortBy:desc', label: 'Date desc.' }
                  ],"class-names":{
                    'ais-SortBy': 'MyCustomSortBy'
                  }}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"col-12 col-md-10 mx-auto"},[_c('ais-search-box',{staticClass:"search-box",attrs:{"placeholder":"Search here...","autofocus":""}})],1)],1),_c('b-row',[_c('ais-current-refinements',{attrs:{"class-names":{
                  'ais-CurrentRefinements': 'MyCustomCurrentRefinements',
                  'ais-CurrentRefinements-item': 'MyCustomCurrentRefinementsItem'
                }}})],1)],1)],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"px-5"},[_c('ais-infinite-hits',{staticClass:"hits",attrs:{"class-names":{
              'ais-InfiniteHits': 'myInfiniteHits',
              'ais-InfiniteHits-list': 'myInfiniteHitsList',
              'ais-InfiniteHits-item': 'myInfiniteHitsItem'},"transform-items":_vm.transformHitItems},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('h4',{staticClass:"center-title"},[_c('ais-highlight',{attrs:{"attribute":"Title","hit":item,"class-names":{'ais-Highlight':'artwork-title'}}}),_c('br'),_c('ais-highlight',{attrs:{"attribute":"Date","hit":item,"class-names":{'ais-Highlight':'artwork-date'}}})],1),(item.ThumbnailURL)?_c('a',{attrs:{"href":item.URL}},[_c('img',{staticClass:"picture",attrs:{"src":item.ThumbnailURL,"alt":item.Title}})]):(item.URL)?_c('p',{staticClass:"center-title"},[_vm._v("No picture available. "),_c('br'),_vm._v(" "),_c('a',{staticClass:"center-title",attrs:{"href":item.URL}},[_vm._v("Go to MoMA's artwork website")])]):_c('p',[_vm._v("No picture available")]),_c('p',{staticClass:"center-title"},[_c('ais-highlight',{attrs:{"attribute":"Artist","hit":item}}),_c('br'),_c('ais-highlight',{directives:[{name:"show",rawName:"v-show",value:(!item.VariousArtists),expression:"!item.VariousArtists"}],attrs:{"attribute":"ArtistBio","hit":item}})],1),_c('p',{staticClass:"center-title"},[_vm._v("Medium "),_c('br'),_c('ais-highlight',{attrs:{"attribute":"Medium","hit":item}})],1),_c('p',{staticClass:"center-title"},[_vm._v("Dimensions "),_c('br'),_c('ais-highlight',{attrs:{"attribute":"Dimensions","hit":item}})],1),_c('p',[_vm._v("Department of "),_c('ais-highlight',{attrs:{"attribute":"Department","hit":item}})],1)]}},{key:"loadMore",fn:function(ref){
              var isLastPage = ref.isLastPage;
              var refineNext = ref.refineNext;
return _c('b-button',{staticClass:"mx-auto my-5",attrs:{"disabled":isLastPage},on:{"click":refineNext}},[_vm._v(" Show more ")])}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }